/*------------------------------------*
	Tease style
*------------------------------------*/

.section {
	padding: 3rem 0;

	@media #{md("s")} {
		padding: 5rem 0;
	}
}

.tease {
	margin-bottom: 5rem;

	@media #{md("s")} {
		margin-bottom: 8rem;
	}

	&:hover {
		.tease__img--hover {
			opacity: 1;
			transition: 0.9s all $out-expo;
		}
	}
}

.chocolat-image {
	cursor: url("../../assets/svg/cursor.svg") 17 17, auto !important;
}

.tease__holder {
	overflow: hidden;
}

.tease__img {
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 1rem;
}

.tease__img--hover {
	position: absolute;
	top: 0;
	pointer-events: none;
	opacity: 0;
	transition: 0.9s all $out-expo;
}

.tease__title {
	margin-bottom: 0.5rem;
	font-weight: 100;
	letter-spacing: $letter-spacing;
	text-transform: uppercase;
}

.tease__cat {
	letter-spacing: $letter-spacing / 2;
	text-transform: uppercase;
	color: $grey;
}

.tease__serie {
	padding-top: 0.5rem;
}

@media #{md("s")} {
	.grid--second {
		margin-top: -8em;
	}
}

.section--slides {
	margin-bottom: 3rem;

	@media #{md("s")} {
		margin-bottom: 5rem;
	}
}

.section__title {
	> h1,
	> h2,
	> h3,
	> h4,
	> h5,
	> h6,
	> p {
		margin-bottom: 2rem;
		letter-spacing: $letter-spacing / 2;
		text-transform: uppercase;
	}
}

.section__content {
	> p {
		margin-bottom: 1.5rem;
		line-height: 2em;
		font-weight: 100;
		color: $grey-dark;
	}

	p:last-of-type {
		margin-bottom: 5rem;
	}
}

.intro {
	h1,
	h2,
	h3,
	h4 {
		margin-bottom: 2rem;
		letter-spacing: $letter-spacing / 2;
		text-transform: uppercase;
	}

	p {
		margin-bottom: 2rem;
		line-height: 2em;
		font-weight: 100;
		color: $grey-dark;
	}
}

.intro__img {
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
}

.slide__img {
	display: block;
	width: 200px;
	height: auto;
}
