/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
	position: relative;

	&:focus {
		outline: none;
	}

	&.is-draggable {
		tap-highlight-color: transparent;
		user-select: none;

		.flickity-viewport {
			cursor: move;
			cursor: grab;

			&.is-pointer-down {
				cursor: -webkit-grabbing;
				cursor: grabbing;
			}
		}
	}
}

.flickity-viewport {
	position: relative;
	height: 100%;
	overflow: hidden;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}
