.btn {
	position: relative;
	display: inline-block;
	padding: 1.4em 2.4em;
	font-size: 0.9rem;
	font-weight: 300;
	letter-spacing: 4px;
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	background: #530027;
	overflow: hidden;
	transition: 0.4s all ease;
}

.btn-inner {
	position: relative;
}

.btn .btn-inner::before {
	content: "";
	position: absolute;
	bottom: -6px;
	left: 0;
	width: 0;
	height: 2px;
	background: #fff;
	transition: 0.5s all ease-in-out;
}

.btn:hover .btn-inner::before,
.btn:focus .btn-inner::before {
	width: 100%;
	transition: 0.5s all ease-in-out;
}

.btn::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #111;
	transform: translateX(-100%);
	transition: 0.4s all ease-in-out;
}

.btn:hover::before,
.btn:focus::before {
	transform: translateX(0);
	transition: 0.4s all ease-in-out;
}

.btn:hover,
.btn:focus {
	transition: 0.6s all ease-in-out;
}
