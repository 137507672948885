/*------------------------------------*\
		Header
\*------------------------------------*/
.header {
	padding: 2rem 0;
}

.logo {
	margin: 0;

	svg {
		display: block;
		max-width: 180px;
		height: auto;
		margin: auto;

		@media #{md("xs")} {
			max-width: 280px;
		}
	}
}

.logo__link {
	display: block;
}

/*------------------------------------*
	Navigation
*------------------------------------*/
.nav--fix {
	z-index: z("default");
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	background-color: $white;

	@media #{md("xs")} {
		position: relative;
		display: block !important;
		height: auto;
	}
}

.nav {
	line-height: 1em;
	text-align: center;
	border-top: 1px solid $grey-lightest;
}

.menu {
	@include reset-ul;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media #{md("xs")} {
		flex-direction: row;
	}
}

.menu__item {
	display: inline-block;
	letter-spacing: $letter-spacing / 2;
	text-transform: uppercase;
	padding: 0 1em;

	a {
		display: block;
		margin: 0 1rem;
		padding: 1rem 0;
		line-height: $line-height;
		text-decoration: none;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 4px;
			background-color: $red;
			transform: translateX(-102%);
			transition: 0.7s all $out-expo;
		}

		&:hover {
			&::before {
				transform: translateX(0);
				transition: 0.7s all $out-expo;
			}
		}
	}

	&.menu__item--active {
		a {
			&::before {
				transform: translateX(0);
				transition: 0.7s all $out-expo;
			}
		}
	}
}

.nav--btn {
	z-index: z("default", +1);
	width: 100%;
	text-align: center;
	cursor: pointer;
	transition: 0.4s all $out-expo;

	span {
		display: inline-block;
		margin: 0 1rem;
		padding: 1rem 0;
	}

	&:active {
		background-color: $grey-lightest;
		transition: 0.4s all $out-expo;
	}
}
