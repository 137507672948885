/*------------------------------------*\
		Grid system (Bootstrap)
\*------------------------------------*/

// Container
.grid {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

// Row
.grid__row {
	@include clearfix;

	// Media queries
	@each $breakpoint in $breakpoints {
		$key: nth($breakpoint, 1);
		$grid-gutter: map-get($grid-gutters, $key);

		@media #{md($key)} {
			margin-right: $grid-gutter * -0.5;
			margin-left: $grid-gutter * -0.5;
		}
	}
}

// Grid and cols
.grid,
[class*="grid__col-"] {
	min-height: 1px;

	// Media queries
	@each $breakpoint in $breakpoints {
		$key: nth($breakpoint, 1);
		$grid-gutter: map-get($grid-gutters, $key);

		@media #{md($key)} {
			padding-right: $grid-gutter * 0.5;
			padding-left: $grid-gutter * 0.5;
		}
	}
}

[class*="grid__col-"] {
	float: left;
}

// Generates the columns class for
// each breakpoints defined
@each $breakpoint in $breakpoints {
	$key: nth($breakpoint, 1);
	$grid-gutter: map-get($grid-gutters, $key);

	// Media queries
	@media #{md($key)} {
		[class*="grid__col-#{$key}--"] {
			display: block;
		}

		.grid__col-#{$key}--no-gutter {
			padding-right: 0;
			padding-left: 0;
		}

		.grid__col-#{$key}--gutter {
			padding-right: $grid-gutter * 0.5;
			padding-left: $grid-gutter * 0.5;
		}

		.grid__col-#{$key}--left {
			float: left;
		}
		.grid__col-#{$key}--right {
			float: right;
		}
		.grid__col-#{$key}--0 {
			display: block;
		}

		// Generate all columns classes
		@for $i from 1 through $grid--columns {
			.grid__col-#{$key}--#{$i} {
				width: $i * 100% / $grid--columns;
			}

			.grid__push-#{$key}--#{$i} {
				margin-left: $i * 100% / $grid--columns;
			}

			.grid__push-#{$key}--0 {
				margin-left: 0;
			}
		}
	}
}
