/*------------------------------------*
	Contact bloc
*------------------------------------*/
.section--contact {
	padding-bottom: 3rem;

	@media #{md("s")} {
		padding-bottom: 5rem;
	}
}

.contact {
	.section__content {
		margin-bottom: 2rem;
	}

	@media #{md("s")} {
		padding: 4rem;
		border: 2px solid $grey-lightest;
	}
}
