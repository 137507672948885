/*------------------------------------*\
		Footer
\*------------------------------------*/
.footer {
	padding: 1.5rem 0;
	background-color: $grey-lightest;

	.logo__link {
		display: block;
		max-width: 50px;
		margin: auto;

		@media #{md("s")} {
			margin: 0;
		}

		svg {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	@media #{md("s")} {
		padding: 3rem 0;
	}
}

.social {
	@include reset-ul;
	margin-top: 3rem;
	text-align: center;

	@media #{md("s")} {
		margin-top: 0.5rem;
		text-align: right;
	}
}

.social__item {
	display: inline-block;
	margin: 0 1em;
	color: $red;
}

.social__link {
	display: block;
	text-decoration: none;
	fill: $red;

	.social__border {
		transform-origin: center;
		transition: 0.8s all $out-expo;
	}

	&:hover {
		.social__border {
			transform: scale(0.95);
			transition: 0.8s all $out-expo;
		}
	}

	svg {
		display: block;
	}
}
