/*------------------------------------*\
		Main
\*------------------------------------*/

.main {
	width: $layout-main--width;
	max-width: $layout-main--max-width;
	margin-right: auto;
	margin-left: auto;
}
