.chocolat-zoomable.chocolat-zoomed {
	cursor: zoom-out;
}
.chocolat-open {
	overflow: hidden;
}
.chocolat-overlay {
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
	background-color: #fff;
	display: none;
	opacity: 0.8;
}
.chocolat-wrapper {
	height: 100%;
	width: 100%;
	position: fixed;
	display: none;
	left: 0;
	top: 0;
	z-index: 16;
	color: #fff;
}
.chocolat-zoomable .chocolat-img {
	cursor: zoom-in;
}
.chocolat-loader {
	height: 32px;
	width: 32px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -16px;
	margin-top: -16px;
	z-index: 11;
	background: url(../images/loader.gif);
	display: none;
}
.chocolat-content {
	position: fixed;
	width: 0px;
	height: 0px;
	left: 50%;
	top: 50%;
	z-index: 14;
	text-align: left;
}

.chocolat-content .chocolat-img {
	position: absolute;
	width: 100%;
	height: 100%;
}
.chocolat-wrapper .chocolat-left {
	z-index: 17;
	position: absolute;
	top: 50%;
	left: 10px;
	display: none;
	width: 40px;
	height: 100px;
	margin-top: -50px;
	background: url("../images/left.svg") 50% 50% no-repeat;
	cursor: pointer;
	transition: 0.4s all $out-expo;

	&:hover {
		transform: scale(1.05);
		transition: 0.4s all $out-expo;
	}
	&:active {
		transform: scale(1.2);
		transition: 0.4s all $out-expo;
	}
}

.chocolat-wrapper .chocolat-right {
	z-index: 17;
	position: absolute;
	top: 50%;
	right: 10px;
	display: none;
	width: 40px;
	height: 100px;
	margin-top: -50px;
	background: url("../images/right.svg") 50% 50% no-repeat;
	cursor: pointer;
	transition: 0.4s all $out-expo;

	&:hover {
		transform: scale(1.05);
		transition: 0.4s all $out-expo;
	}
	&:active {
		transform: scale(1.2);
		transition: 0.4s all $out-expo;
	}
}

.chocolat-wrapper .chocolat-right.active {
	display: block;
}
.chocolat-wrapper .chocolat-left.active {
	display: block;
}
.chocolat-wrapper .chocolat-top {
	position: absolute;
	top: 0px;
	right: 0;
	left: 0;
	line-height: 50px;
	height: 50px;
	overflow: hidden;
	z-index: 17;
	margin-bottom: 10px;
}
.chocolat-wrapper .chocolat-close {
	width: 40px;
	height: 40px;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
	background: url("../images/close.svg") 50% 50% no-repeat;
	transition: 0.4s all $out-expo;

	&:hover {
		transform: scale(1.05);
		transition: 0.4s all $out-expo;
	}
	&:active {
		transform: scale(1.2);
		transition: 0.4s all $out-expo;
	}
}

.chocolat-wrapper .chocolat-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	line-height: 40px;
	height: 40px;
	font-size: 12px;
	z-index: 17;
	padding-left: 15px;
	padding-right: 15px;
	background: #999;
	text-align: right;
	margin-top: 10px;
}

.chocolat-wrapper .chocolat-set-title {
	display: inline-block;
	padding-right: 15px;
	line-height: 1;
	border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.chocolat-wrapper .chocolat-pagination {
	float: right;
	display: inline-block;
	padding-left: 15px;
	padding-right: 15px;
	margin-right: 15px;
	/*border-right: 1px solid rgba(255, 255, 255, 0.2);*/
}
.chocolat-wrapper .chocolat-fullscreen {
	width: 16px;
	height: 40px;
	background: url(../images/fullscreen.png) 50% 50% no-repeat;
	display: block;
	margin: auto;
	cursor: pointer;
	float: right;
}
.chocolat-wrapper .chocolat-description {
	display: inline-block;
	float: left;
}

/* no container mode*/
body.chocolat-open > .chocolat-overlay {
	z-index: 15;
}
body.chocolat-open > .chocolat-loader {
	z-index: 15;
}
body.chocolat-open > .chocolat-content {
	z-index: 17;
}

/* container mode*/
.chocolat-in-container .chocolat-wrapper,
.chocolat-in-container .chocolat-content,
.chocolat-in-container .chocolat-overlay {
	position: absolute;
}
.chocolat-in-container {
	position: relative;
}

/* uncomment to hide controls when zoomed-in*/
/*
.chocolat-zoomable .chocolat-top,
.chocolat-zoomable .chocolat-bottom,
.chocolat-zoomable .chocolat-right,
.chocolat-zoomable .chocolat-left {
    transition: opacity .3s ease, visibility 0s .3s;
   opacity: 1;
}

.chocolat-zoomable.chocolat-zoomed .chocolat-top,
.chocolat-zoomable.chocolat-zoomed .chocolat-bottom,
.chocolat-zoomable.chocolat-zoomed .chocolat-right,
.chocolat-zoomable.chocolat-zoomed .chocolat-left {
    visibility: hidden;
    opacity: 0;
}
*/
