/*---------------------------------------*\
		Sélection
\*---------------------------------------*/

::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

/*---------------------------------------*\
		Réglages globaux
\*---------------------------------------*/

*,
*:after,
*:before {
	position: relative;
	outline: none;
}

html {
	font-size: $font-size - 2;

	@media #{md("s")} {
		font-size: $font-size - 1;
	}

	@media #{md("m")} {
		font-size: $font-size;
	}
}

// Better font rendering for mac
.is-mac,
.is-mac input,
.is-mac button,
.is-mac textarea {
	@include antialiased;
}

/*------------------------------------*\
		Base typographique

		More settings:
		http://type-scale.com/
\*------------------------------------*/
body {
	font-family: $sans;
	font-size: 1em;
	line-height: $line-height;
	font-weight: 300;
	color: $font-color;
}

p,
ul,
ol,
blockquote {
	margin-bottom: $line-height * 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 500;
}

small {
	font-size: 0.75em;
}

sup {
	vertical-align: super;
	font-size: 0.75em;
}

a {
	color: inherit;
}
