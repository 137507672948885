/*------------------------------------*
	Hero
*------------------------------------*/

.hero {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 500px;
	background-color: #000;
	overflow: hidden;

	@media #{md("s")} {
		flex-direction: row;
		justify-content: flex-start;
	}
}

.hero__inner {
	width: 80%;
	height: 100%;
	margin: auto;

	@media #{md("s")} {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		width: 90%;
		height: 100%;
	}

	img {
		max-width: 100%;
		height: auto;
	}
}

.hero__content {
	max-width: 30em;
	margin: auto;
	text-align: center;
	color: $white;

	h1 {
		display: inline;
		font-size: 1.8rem;
		letter-spacing: $letter-spacing / 2;
		text-transform: uppercase;
		background-image: linear-gradient(to top, $red 0, $red 0),
			linear-gradient(to top, transparent 0, transparent 0);
		background-position: left bottom -12px, left bottom 6px;
		background-repeat: no-repeat, no-repeat;
		background-size: 100% 16px, 100% 16px;

		@media #{md("s")} {
			font-size: 2.4rem;
		}
	}

	p {
		margin-top: 1.5rem;
		margin-bottom: 3rem;
		font-size: 1.125rem;
		line-height: 2em;
		font-weight: 100;

		@media #{md("s")} {
			margin-bottom: 0;
		}
	}

	@media #{md("s")} {
		margin: 0;
		text-align: left;
	}
}
